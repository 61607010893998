import { render, staticRenderFns } from "./rank-table4.vue?vue&type=template&id=14487624&scoped=true"
import script from "./rank-table4.vue?vue&type=script&lang=js"
export * from "./rank-table4.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14487624",
  null
  
)

export default component.exports